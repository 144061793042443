<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-layout wrap justify-start>
          <v-flex px-4 xs12 sm6 md4 lg4 xl4 text-left>
            <span class="itemHeading">Admin List </span>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl3>
            <v-text-field
              v-model="keyword"
              append-icon="mdi-magnify"
              solo
              label="Search"
              color="#68D389"
              single-line
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md5 lg5 xl5>
            <download-excel :data="json_data" :fields="json_fields">
              <span
                style="
                  font-size: 16px;
                  font-family: poppinssemibold;
                  color: #68d389;
                  cursor: pointer;
                "
              >
                Download Excel</span
              >
            </download-excel>
          </v-flex>
        </v-layout>
        <v-card-title class="elevation-0">
          <!-- <span class="itemHeading">Admin</span> -->
          <v-spacer></v-spacer>
          <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs12 px-5>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              width="70%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="appType == 'admin'"
                  style="font-family: poppinsmedium"
                  outlined
                  block
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Admin
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat" v-if="!notify">
                  <v-card-title>
                    <span class="headline">Add Admin Info</span>
                  </v-card-title>
                  <!-- <v-card-text>
                    <v-container>
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm10 align-self-center>

                          <v-select
                            :items="admins"
                            label="Role"
                            dense
                            item-text="label"
                            item-value="name"
                            :rules="roleRules"
                            v-model="role"
                            outlined
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text> -->
                  <!-- <v-card-text> -->
                  <!-- <v-container> -->
                  <v-layout wrap justify-center pt-4>
                    <v-flex xs12 sm10 align-self-center>
                      <v-select
                        :items="admins"
                        label="Role"
                        dense
                        item-text="label"
                        item-value="name"
                        :rules="roleRules"
                        v-model="role"
                        outlined
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm10 align-self-center>
                      <v-text-field
                        outlined
                        dense
                        v-model="email"
                        label="Email"
                        :rules="emailRules"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <!-- </v-container> -->
                  <!-- </v-card-text> -->
                  <!-- <v-card-text> -->
                  <!-- <v-container> -->
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm10 align-self-center>
                      <v-text-field
                        outlined
                        dense
                        maxlength="10"
                        minlength="10"
                        counter
                        :rules="phnoRules"
                        type="number"
                        v-model="phone"
                        label="Phone"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <!-- </v-container> -->
                  <!-- </v-card-text> -->
                  <!-- <v-card-text> -->
                  <!-- <v-container> -->
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm10 align-self-center>
                      <v-text-field
                        type="password"
                        outlined
                        dense
                        v-model="password"
                        label="Password"
                        :rules="[rules.required]"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <!-- </v-container> -->
                  <!-- </v-card-text> -->
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogclose()">
                      Close
                    </v-btn>
                    <v-btn
                      v-if="role !== 'employee'"
                      color="blue darken-1"
                      text
                      :disabled="!addcat && rules.mzg !== ''"
                      @click="adminsdataadd()"
                    >
                      Next
                    </v-btn>
                    <v-btn
                      v-if="role == 'employee'"
                      color="blue darken-1"
                      text
                      :disabled="!addcat && rules.mzg !== ''"
                      @click="adminsdataadd()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
                <v-form v-model="addcatt" ref="addcatt" v-if="notify">
                  <v-card-title>
                    <span class="headline">Add Admin Privilege</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container v-for="(item, i) in menus" :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm10 align-self-center>
                          <v-checkbox
                            v-model="item.isChecked"
                            :label="item.name"
                            color="#68D389"
                            @click="reCheck(item, item.isChecked)"
                          ></v-checkbox>
                        </v-flex>
                      </v-layout>
                      <v-container v-if="item.isChecked">
                        <v-layout
                          wrap
                          justify-center
                          v-for="(sub, j) in item.subMenus"
                          :key="j"
                        >
                          <v-flex xs12 sm10 px-4 align-self-center>
                            <v-checkbox
                              v-model="sub.isChecked"
                              :label="sub.name"
                              color="#68D389"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <!-- <v-container v-if="mark && item.isChecked ">
                        <v-layout
                          wrap
                          justify-center
                          v-for="(sub, j) in item.subMenus"
                          :key="j"
                        >
                          <v-flex xs12 sm10 px-4 align-self-center>
                            <v-checkbox
                              v-model="sub.isChecked"
                              :label="sub.name"
                              color="#68D389"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-container> -->
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogclose()">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addcatt && rules.mzg !== ''"
                      @click="addMenu()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex
            xs12
            sm12
            align-self-center
            v-for="(item, i) in adminsdata"
            :key="i"
            fill-height
            pa-4
          >
            <!-- <v-card tile outlined> -->
            <v-layout wrap justify-center py-4>
              <v-flex
                :text-center="$vuetify.breakpoint.name == 'xs' ? true : false"
                xs12
                sm4
                text-left
              >
                <span style="font-family: poppinslight"
                  >Email<br />
                  <b>{{ item.email }}</b></span
                >
              </v-flex>
              <v-flex
                :text-center="$vuetify.breakpoint.name == 'xs' ? true : false"
                xs12
                sm2
                text-left
              >
                <span style="font-family: poppinslight"
                  >Phone Number <br />
                  <b>{{ item.phone }}</b></span
                >
              </v-flex>
              <v-flex
                :text-center="$vuetify.breakpoint.name == 'xs' ? true : false"
                xs12
                sm2
                text-left
              >
                <span
                  v-if="item.role == 'subadmin'"
                  class="text-capitalize"
                  style="font-family: poppinslight"
                  >Role <br />
                  <b>Sub Admin</b></span
                >
                <span
                  v-if="item.role == 'employee'"
                  class="text-capitalize"
                  style="font-family: poppinslight"
                  >Role <br />
                  <b>App User</b></span
                >
              </v-flex>
              <v-flex sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm4 pa-2>
                    <v-btn
                      color="#03bafc"
                      style="font-family: poppinsmedium"
                      small
                      block
                      outlined
                      @click="viewCat(item)"
                      >View</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 sm4 pa-2>
                    <v-btn
                      v-if="appType == 'admin'"
                      color="success"
                      style="font-family: poppinsmedium"
                      small
                      block
                      outlined
                      @click="editcat(item)"
                      >Edit</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 sm4 pa-2>
                    <v-dialog
                      persistent
                      v-model="item.delete"
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="appType == 'admin'"
                          small
                          style="font-family: poppinsmedium"
                          class="mr-2"
                          outlined
                          block
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          admin?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- </v-card> -->

            <v-divider></v-divider>
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="70%">
            <v-card v-if="!editNotify">
              <v-form v-model="editcatt" ref="editcatt">
                <v-card-title>
                  <span class="headline">Edit Admin details</span>
                </v-card-title>
                <!-- <v-card-text> -->
                <v-layout wrap justify-center>
                  <v-flex xs12 sm10 pt-4>
                    <!-- <v-text-field
                      outlined
                      dense
                      v-model="editingitem.role"
                      label=" Email"
                      :rules="roleRules"
                      required
                    >
                    </v-text-field> -->
                    <v-select
                      :items="admins"
                      label="Role"
                      dense
                      item-text="label"
                      item-value="name"
                      :rules="roleRules"
                      v-model="editingitem.role"
                      disabled
                      outlined
                    ></v-select>
                  </v-flex>
                </v-layout>
                <!-- </v-card-text> -->
                <!-- <v-card-text> -->
                <v-layout wrap justify-center>
                  <v-flex xs12 sm10>
                    <v-text-field
                      outlined
                      dense
                      v-model="editingitem.email"
                      label=" Email"
                      :rules="emailRules"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <!-- </v-card-text> -->
                <!-- <v-card-text> -->
                <v-layout wrap justify-center>
                  <v-flex xs12 sm10>
                    <v-text-field
                      outlined
                      dense
                      maxlength="10"
                      minlength="10"
                      counter
                      :rules="phnoRules"
                      type="number"
                      v-model="editingitem.phone"
                      label=" Phone"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <!-- </v-card-text> -->
                <v-card-text>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm10>
                      <v-btn
                        v-if="display == false"
                        color="red"
                        outlined
                        @click="display = true"
                      >
                        Edit Password
                      </v-btn>
                      <v-text-field
                        outlined
                        dense
                        type="password"
                        v-if="display == true"
                        v-model="editingitem.password"
                        label="Password"
                        :rules="[rules.required]"
                        required
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="getdData">
                    Close
                  </v-btn>
                  <v-btn
                    v-if="!flag && editingitem.role !== 'employee'"
                    color="blue darken-1"
                    text
                    :disabled="!editcatt"
                    @click="edit(), (editNotify = true)"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    v-if="!flag && editingitem.role == 'employee'"
                    color="blue darken-1"
                    text
                    :disabled="!editcatt"
                    @click="edit(), (editNotify = false), (editdialog = false)"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
            <v-card v-if="editNotify">
              <v-form v-model="addcatt" ref="addcatt">
                <v-card-title>
                  <span class="headline">Add Admin Privilege</span>
                </v-card-title>
                <v-card-text>
                  <v-container v-for="(item, i) in about" :key="i">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm10 align-self-center>
                        <v-checkbox
                          v-model="item.isChecked"
                          :label="item.name"
                          color="#68D389"
                          @click="reCheck(item, item.isChecked)"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                    <v-container v-if="item.isChecked">
                      <v-layout
                        wrap
                        justify-center
                        v-for="(sub, j) in item.subMenus"
                        :key="j"
                      >
                        <v-flex xs12 sm10 px-4 align-self-center>
                          <v-checkbox
                            v-model="sub.isChecked"
                            :label="sub.name"
                            color="#68D389"
                          ></v-checkbox>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <!-- <v-container v-if="mark && item.isChecked ">
                        <v-layout
                          wrap
                          justify-center
                          v-for="(sub, j) in item.subMenus"
                          :key="j"
                        >
                          <v-flex xs12 sm10 px-4 align-self-center>
                            <v-checkbox
                              v-model="sub.isChecked"
                              :label="sub.name"
                              color="#68D389"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-container> -->
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogclose()">
                    Close
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!addcatt && rules.mzg !== ''"
                    @click="updateMenu(editingitem._id)"
                  >
                    Save
                  </v-btn>
                  <!-- @click="updateMenu(editingitem._id)" -->
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-dialog
      :retain-focus="false"
      v-model="viewdialog"
      persistent
      max-width="70%"
    >
      <v-card>
        <v-form>
          <v-card-title>
            <span class="headline">View Admin Info</span>
          </v-card-title>
          <!-- <v-card-text>
                    <v-container>
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm10 align-self-center>

                          <v-select
                            :items="admins"
                            label="Role"
                            dense
                            item-text="label"
                            item-value="name"
                            :rules="roleRules"
                            v-model="role"
                            outlined
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text> -->
          <!-- <v-card-text> -->
          <!-- <v-container> -->
          <v-layout wrap justify-center pt-4>
            <v-flex xs12 sm10 align-self-center>
              <v-text-field
                outlined
                dense
                v-model="viewitem.email"
                label="Email"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!-- </v-container> -->
          <!-- </v-card-text> -->
          <!-- <v-card-text> -->
          <!-- <v-container> -->
          <v-layout wrap justify-center>
            <v-flex xs12 sm10 align-self-center>
              <v-text-field
                outlined
                dense
                maxlength="10"
                :rules="phnoRules"
                type="number"
                v-model="viewitem.phone"
                label="Phone"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!-- </v-container> -->
          <!-- </v-card-text> -->
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="viewdialog = false">
              Close
            </v-btn>
          </v-card-actions> -->
        </v-form>
        <v-form v-if="about.length > 0">
          <v-card-title v-if="about.length > 0 && viewitem.role !== 'employee'">
            <span class="headline">Admin Privileges</span>
          </v-card-title>
          <v-card-text>
            <template v-for="(item, i) in about">
              <v-layout wrap justify-center :key="i">
                <v-flex xs12 sm10 align-self-center v-if="item.isChecked">
                  <v-checkbox
                    v-model="item.isChecked"
                    :label="item.name"
                    color="#68D389"
                    disabled
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <!-- <v-flex flat :key="item.name" style="border:2px solid blue"> -->
              <v-layout
                wrap
                justify-center
                v-for="(sub, j) in item.subMenus"
                :key="j.name"
              >
                <v-flex xs12 sm10 px-4 align-self-center v-if="sub.isChecked">
                  <v-checkbox
                    disabled
                    v-model="sub.isChecked"
                    :label="sub.name"
                    color="#68D389"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <!-- </v-flex> -->
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="viewdialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../../store";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      timeout: 3000,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      email: "",
      phone: "",
      password: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      flag: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      addcatt: false,
      editcatt: false,
      keyword: "",
      role: null,
      admins: [
        { name: "subadmin", label: "Sub Admin" },
        { name: "employee", label: "App User" },
      ],

      json_fields: {
        PhoneNumber: "phone",
        Email: "email",
        Role: "role",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      roleRules: [(v) => !!v || "Role is required"],
      phnoRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length < 11 || "Invalid Phone",
        (v) =>
          /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(v) ||
          "Phone is invalid",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        // min: (v) => v.length >= 8 || "Min 8 characters",
      },
      display: false,

      adminsdata: [],
      data: [],
      notify: false,
      editNotify: false,
      notifyId: null,
      checkbox: false,
      menus: [],
      menusUp: [],
      viewitem: [],
      viewdialog: false,
      about: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    appType() {
      return store.state.userType;
    },
  },
  watch: {
    keyword() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    reCheck(item, check) {
      if (!check) {
        for (let i = 0; i < item.subMenus.length; i++) {
          item.subMenus[i].isChecked = false;
        }
      }
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },
    validURL(url) {
      var pattern =
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(([\w#!:.?+=&%@!]))?/;
      if (pattern.test(url)) {
        this.rules.mzg = "";
        return true;
      }
      this.rules.mzg = "Invalid URL";
      return false;
    },
    initialize() {
      this.admin = [];
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/user/removeadmin",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/adminlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.adminsdata = response.data.data;
          this.json_data = this.adminsdata;
          // this.name = this.guest.name;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.role = null;
      this.email = null;
      this.phone = null;
      this.notify = false;
      this.editNotify = false;
      // this.password = null;
      this.menus = [];
      this.about = [];
      this.dialog = false;
      this.editdialog = false;
      this.getData();
    },
    adminsdataadd() {
      var data = {};
      data["role"] = this.role;
      data["email"] = this.email;
      data["phone"] = this.phone;
      data["password"] = this.password;
      axios({
        url: "/user/createadmin",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.role == "employee") {
              this.notify = false;
              this.dialog = false;
            }
            this.notifyId = response.data.data._id;
            this.menus = response.data.menu;
            this.notify = true;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.role = null;
            this.email = null;
            this.phone = null;
            this.password = null;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addMenu() {
      this.appLoading = true;
      var data = {};
      data["id"] = this.notifyId;
      data["menu"] = this.menus;
      axios({
        url: "/user/udateadminrights",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.role = null;
            this.email = null;
            this.phone = null;
            this.password = null;
            this.menus = [];
            this.notify = false;
            this.editNotify = false;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // validateMenu(id) {
    //   let myFlag = false;
    //   // let not = [];
    //   // let yes = [];
    //   let allJ = 0;
    //   // // let allI=0
    //   let count = 0;
    //   let countt = 0;
    //   for (let i = 0; i < this.about.length; i++) {
    //     for (let j = 0; j < this.about[i].subMenus.length; j++) {
    //       if (this.about[i].isChecked) {
    //         if (this.about[i].subMenus[j].isChecked) {
    //           count++
    //           if(count>0){
    //             myFlag=true

    //           }
    //           // console.log("YES=");
    //           // break
    //           // allJ = j;
    //           // count++;
    //           // yes.push(this.about[i].subMenus[j].name);
    //         } else {
    //           myFlag=false
    //           countt++
    //           // not.push(this.about[i].subMenus[j].name);
    //           // console.log("Not selected=");
    //         }

    //       }

    //       if(count+countt !== allJ){
    //             console.log("j",allJ)
    //             console.log("crt",countt+count)
    //             myFlag=false
    //             // break
    //           }
    //     }

    //     // count=0
    //   }

    //   if (!myFlag) {
    //     console.log("OOPS");
    //   } else {
    //     console.log("Okk");
    //     // this.updateMenu(id);
    //   }
    //   console.log(id);
    // },
    updateMenu(id) {
      this.appLoading = true;
      var data = {};
      data["id"] = id;
      data["menu"] = this.about;
      axios({
        url: "/user/udateadminrights",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.role = null;
            this.email = null;
            this.phone = null;
            this.password = null;
            this.menus = [];
            this.about = [];
            this.editNotify = false;
            this.editdialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
      this.editGet(this.editingitem._id);
    },
    editGet(id) {
      this.appLoading = true;
      axios({
        url: "/user/adminget",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.about = response.data.menu;
          // console.log("sd", this.about);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      if (this.editingitem.email) {
        var data = {};
        if (this.editingitem.password) {
          data["id"] = this.editingitem._id;
          data["role"] = this.editingitem.role;
          data["email"] = this.editingitem.email;
          data["phone"] = this.editingitem.phone;
          data["password"] = this.editingitem.password;
        } else {
          data["id"] = this.editingitem._id;
          data["role"] = this.editingitem.role;
          data["email"] = this.editingitem.email;
          data["phone"] = this.editingitem.phone;
        }
        axios({
          url: "/user/editadmin",
          method: "POST",
          data: data,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            // this.editdialog = false;
            this.display = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Edited Sucessfully";
              this.showsnackbar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    viewCat(item) {
      this.viewitem = item;
      this.viewdialog = true;
      this.editGet(this.viewitem._id);
    },
  },
};
</script>